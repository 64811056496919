import moment from 'moment';
import { connect } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import EventBus from "eventing-bus";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './index.css';
import '../../static/css/animate.css';
import 'animate.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { ShareButton } from '../../components/shareButton';
import { toggleLoader, donateAmount, getItemSold } from "../../store/actions/Auth";

// const { shareIcon } = '../../static/images/share.svg';


const stripePromise = loadStripe(process.env.REACT_APP_API_KEY);

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "black",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "black" },
            "::placeholder": { color: "black" }
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "black"
        }
    }
}

const DonationPaymentMethod = (props) => {

    const stripe = useStripe()
    const elements = useElements()

    let [amount, setAmount] = useState(0);
    let [salePrice, setSalePrice] = useState(0);
    let [selectedCurrency, setSelectedCurrency] = useState("HKD");

    let [name, setName] = useState("");
    let [email, setEmail] = useState("");
    let [phone, setPhone] = useState("");

    let [modalIsOpen, setModalIsOpen] = useState(false);
    const toggle = () => setModalIsOpen(!modalIsOpen);


    useEffect(() => {
        if (props.saleResponse == true) {
            setModalIsOpen(true);
        }
    }, [props.saleResponse]);

    useEffect(() => {
        if (props.userData && Object.keys(props.userData).length > 0) {
            setName(props.userData.name)
            setEmail(props.userData.email)
            setPhone(props.userData.phone)
        }
    }, [props.userData]);

    const handlePayment = async () => {
        try {
            if (parseInt(amount) <= 0) return EventBus.publish("error", "Please enter amount to donate");
            if (parseInt(salePrice) <= 0) return EventBus.publish("error", "Please enter amount to donate");
            if (name == undefined || name == null || name.trim().length === 0) return EventBus.publish("error", "Please update you name");
            if (email == undefined || email == null || email.trim().length === 0) return EventBus.publish("error", "Please update you email");
            if (phone == undefined || phone == null || phone.trim().length === 0) return EventBus.publish("error", "Please update you mobile number");

            props.toggleLoader({ message: "Donating...", status: true });
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement),
                billing_details: {
                    name: name,
                    email: email,
                    phone: phone,
                },
            })
            if (!error) {
                const { id } = paymentMethod

                props.donateAmount({ amount: parseInt(salePrice), id, currency: selectedCurrency, userName: name, userEmail: email, phone });
            } else {
                props.toggleLoader({ message: "Donating Amount...", status: false });
                return EventBus.publish("error", error.message);
            }
        } catch (error) {
            props.toggleLoader({ message: "Donating Amount...", status: false });
            return EventBus.publish("error", error.message);
        }
    }

    const handleEditChange = (e) => {
        if (e.target.name == "name") setName(e.target.value);
        if (e.target.name == "email") setEmail(e.target.value);
        if (e.target.name == "phone") setPhone(e.target.value);
        if (e.target.name == "amount") {
            if (parseInt(e.target.value) > 0) {
                setSalePrice(parseInt(e.target.value) + ((parseInt(e.target.value) / 100) * 3.52))
                setAmount(parseInt(e.target.value));
            } else {
                setAmount(parseInt(0));
                setSalePrice(parseInt(0));
            }
        }
    }

    return (
        <div className="wrapper">
            {/* <Navbar />
            <section class="inner-page-banner">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <h1>Payment Method</h1>
                            <ul className="bredcamb">
                                <li><Link className="bredcamb-link" to="/">Home</Link></li>
                                <li>/</li>
                                <li><Link className="bredcamb-link" to="/Buy">Buy</Link></li>
                                <li>/</li>
                                <li><Link className="bredcamb-link active" to="#">Payment Method</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className='payment-method-page'>
                <div className='auto-container'>
                    <div className='row'>
                        <div className='col-lg-10 offset-lg-1 col-md-12'>
                            <ValidatorForm className='form'>
                                <div className='row'>
                                    <div className='col-lg-7 col-md-12'>
                                        <div className='left-content-area'>
                                            <div className='form-block'>
                                                <h3>User Details</h3>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Name </label>
                                                    <TextValidator
                                                        type='text'
                                                        id='name'
                                                        name='name'
                                                        placeholder='Enter your full name'
                                                        value={name}

                                                        className='form-control'
                                                        onChange={handleEditChange}
                                                        // validators={['required']}
                                                        errorMessages={['Name is required']}
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='email'>Email </label>
                                                    <TextValidator
                                                        type='email'
                                                        id='email'
                                                        name='email'
                                                        placeholder='Enter your email address here'
                                                        value={email}

                                                        className='form-control'
                                                        onChange={handleEditChange}

                                                        // validators={['required']}
                                                        errorMessages={['email is required']}
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='phone'>Mobile Number</label>
                                                    <TextValidator
                                                        type='text'
                                                        id='phone'
                                                        name='phone'
                                                        placeholder='Enter you phone'
                                                        value={phone}
                                                        className='form-control'
                                                        onChange={handleEditChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-block'>
                                                <h3>Donation</h3>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Currency </label>
                                                    {/* <select onChange={(e) => selectCurrency(e.target.value)} value={selectedCurrency}>
                                                        <option value="HKD">HKD</option>
                                                    </select> */}
                                                    <TextValidator
                                                        type='text'
                                                        id='currency'
                                                        name='currency'
                                                        placeholder='Currency'
                                                        value={selectedCurrency}

                                                        className='form-control'
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Donation Amount </label>
                                                    <TextValidator
                                                        type='text'
                                                        id='amount'
                                                        name='amount'
                                                        placeholder='Donation Amount'
                                                        value={amount}
                                                        onChange={handleEditChange}

                                                        className='form-control'
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Card Number </label>
                                                    <CardNumberElement className="form-control" options={CARD_OPTIONS} />
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <div className='form-group'>
                                                            <label htmlFor='name'>Card Expiry </label>
                                                            <CardExpiryElement className="form-control" options={CARD_OPTIONS} />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <div className='form-group'>
                                                            <label htmlFor='name'>Card CVC </label>
                                                            <CardCvcElement className="form-control" options={CARD_OPTIONS} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='form-block'>
                                                <div id="accordion">
                                                    <div class="card">
                                                        <div class="card-header" id="headingTwo">
                                                            <h5 class="mb-0">
                                                                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    Donation Summary
                                                                    <i className='icon'><img src="images/top-arrow.svg" alt="" /></i>
                                                                </button>
                                                            </h5>
                                                        </div>

                                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                                                            <div class="card-body">
                                                                <h5>Title <span>Donation</span></h5>
                                                                <h5>Donation Amount <span>{amount.toFixed(2)} {selectedCurrency} + 3.52% transaction charges</span></h5>
                                                                <h5>Grand Total <span>{salePrice.toFixed(2)} {selectedCurrency}</span></h5>
                                                                <div className='form-group'>
                                                                    <button className='btn-style-one' onClick={handlePayment}>Submit</button>
                                                                </div>
                                                                {
                                                                    props.saleResponse == true &&
                                                                    <ShareButton
                                                                        modifiers={['secondary']}
                                                                        value="Share"
                                                                        shareUrl={window.location.href}
                                                                        className="btn-style-one"
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

            {/* ---------------SUCCESS MODAL--------------- */}

            <Modal
                isOpen={modalIsOpen} toggle={toggle}
                className='main-modal'
                style={{ marginTop: "350px" }}
            >
                <ModalBody className='modal-body'>
                    <div className="success-text-box">
                        <h3>Donated Amount Successfully</h3>
                    </div>
                </ModalBody>
            </Modal>
        </div >
    );
}


const mainFunction = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <DonationPaymentMethod {...props} />
        </Elements>
    )

}

const mapDispatchToProps = { toggleLoader, donateAmount, getItemSold };

const mapStateToProps = ({ Auth }) => {
    let { userData, saleResponse } = Auth;
    return { userData, saleResponse }
};

export default connect(mapStateToProps, mapDispatchToProps)(mainFunction);