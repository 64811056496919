import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import './index.css';
import EventBus from 'eventing-bus';
import '../../static/css/animate.css';
import 'animate.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { ShareButton } from '../../components/shareButton';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { toggleLoader, getVotings, addVote, submitVotingChoice } from "../../store/actions/Auth";

const { shareIcon } = '../../static/images/share.svg';


const Voting = (props) => {

    let [name, setName] = useState("");
    let [instagramUrl, setinstagramUrl] = useState("");
    let [category, setCategory] = useState("Best-Dance-MTV-Director-Awards");

    useEffect(() => {
        // props.toggleLoader({ message: "Get votings...", status: true });
        // props.getVotings();
    }, []);

    async function handleEditChange(e) {
        if ([e.target.name] == "name") {
            setName(e.target.value);
        } else if ([e.target.name] == "instagramUrl") {
            setinstagramUrl(e.target.value);
        } else if ([e.target.name] == "category") {
            setCategory(e.target.value);
        }
    }

    async function submitChoice(e) {
        try {
            e.preventDefault();

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            if (!props.isLogin) return props.history.push("/Login");

            if (name == "") {
                EventBus.publish("error", `Please enter name`);
                return;
            }

            if (instagramUrl == "") {
                EventBus.publish("error", `Please enter instagram url`);
                return;
            }

            if (category == "") {
                EventBus.publish("error", `Please select category`);
                return;
            }

            // if (!instagramUrl.includes("instagram.com")) {
            //     EventBus.publish("error", "Invalid instagram account url");
            //     return
            // }

            props.toggleLoader({ message: "Submitting choice...", status: true });

            await props.submitVotingChoice({ name, instagramUrl, category });

            waitFor(1500);

            setName("");
            setinstagramUrl("");
            setCategory("Best-Dance-MTV-Director-Awards");
        } catch (e) {
            console.log(e);
            props.toggleLoader({
                message: "Submission Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Unable to submit choice`);
        }
    }

    return (
        <div className="wrapper">
            <Navbar />
            <section class="inner-page-banner">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <h1>Voting</h1>
                            <ul className="bredcamb">
                                <li><Link className="bredcamb-link" to="/">Home</Link></li>
                                <li>/</li>
                                <li><Link className="bredcamb-link active" to="#">Voting</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="events-sec style-two gallery-page">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className="sec-title text-center">
                                <p>Voting Poll</p>
                                <h2>Submit your choice for voting polls</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-3 col-md-12">
                            <ValidatorForm className="validator-form">
                                <div className='form-group'>
                                    <label>Choose Category</label>
                                    <select name="category" id="category" onChange={handleEditChange}>
                                        <optgroup label="幕後獎項 ( Production Awards )">
                                            <option na value="Best-Dance-MTV-Director-Awards">年度舞曲MTV導演獎項 ( Best Dance MTV Director Awards )</option>
                                            <option value="Best-Concerts-Choreography-Awards">年度最喜愛演唱會編舞 ( Best Concerts Choreography Awards )</option>
                                            <option value="Best-MTV-Choreography-Awards">年度最喜愛MTV編舞 ( Best MTV Choreography Awards )</option>
                                        </optgroup>
                                        <optgroup label="幕前獎項 ( Performance Awards )">
                                            <option value="Best-Dance-Video-Awards">年度舞蹈片段獎項 ( Best Dance Video Awards )</option>
                                            <option value="Best-Live-Dance-Performance-Awards">年度最喜愛現場舞蹈演出 ( Best Live Dance Performance Awards )</option>
                                            <option value="Best-MTV-Dance-Awards">年度最喜愛MTV舞蹈演出 ( Best MTV Dance Awards )</option>
                                        </optgroup>
                                        <optgroup label="年度大獎 ( Yearly Awards )">
                                            <option value="Best-Dancing-Crew">年度最喜愛團隊 ( Best Dancing Crew )</option>
                                            <option value="Best-Male-Dancer">年度最喜愛男舞者 ( Best Male Dancer )</option>
                                            <option value="Best-Female-Dancer">年度最喜愛女舞者 ( Best Female Dancer )</option>
                                        </optgroup>
                                    </select>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name'>Name </label>
                                    <TextValidator
                                        fullWidth
                                        type='name'
                                        name='name'
                                        value={name}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='instagramUrl'>Instagram URL </label>
                                    <TextValidator
                                        fullWidth
                                        type='instagramUrl'
                                        name='instagramUrl'
                                        value={instagramUrl}
                                        id='standard-full-width'
                                        className='form-input-field'
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className='form-group btn-group text-center'>
                                    <button type="Submit" className='btn-style-one' onClick={submitChoice}>
                                        Submit
                                    </button>
                                </div>
                            </ValidatorForm>
                        </div>
                        {/* <div class="col-12">
                            <div className="sec-title text-center">
                                <p>Voting Poll</p>
                                <h2>Cast Your Vote to support Us</h2>
                            </div>
                        </div>
                        {props.setVoting && props.setVoting.length > 0 &&
                            props.setVoting.map((items, index) =>
                                <div className='col-lg-4 col-md-6 col-sm-12' key={index}>
                                    <div className="event-item">
                                        <div className="text-box">
                                            <h3>{items['findVotings']['title']}</h3>
                                            <p>{items['findVotings']['description']}</p>
                                            <Link className='btn-style-one' to={`/CastVote/${items['findVotings']['_id']}`}>Vote Now</Link>
                                            {
                                                props.voteResponse == true &&
                                                <ShareButton
                                                    modifiers={['secondary']}
                                                    icon={shareIcon}
                                                    value="Share"
                                                    shareUrl={window.location.href}
                                                    className="btn-style-one"
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            )} */}
                        {/* <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className="event-item">
                                <div className="text-box">
                                    <h3>Voting Poll title</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printiiig and typesetting industry. </p>
                                    <span className='price'>Item 1 <i className='percentage'>(56%)</i></span>
                                    <span className='price'>Item 2 <i className='percentage'>(44%)</i></span>
                                    <Link className='btn-style-one' to="/CastVote">Vote Now</Link>
                                    {
                                        props.voteResponse == true &&
                                        <ShareButton
                                            modifiers={['secondary']}
                                            icon={shareIcon}
                                            value="Share"
                                            shareUrl={window.location.href}
                                            className="btn-style-one"
                                        />
                                    }
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            <Footer />
        </div >
    );
}

const mapDispatchToProps = { toggleLoader, getVotings, addVote, submitVotingChoice };

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, setVoting, isLogin, setLoader, voteResponse } = Auth;
    return { publicAddress, setVoting, isLogin, setLoader, voteResponse }
};

export default connect(mapStateToProps, mapDispatchToProps)(Voting);