export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const setLogin = (data) => ({
  type: 'SET_LOGIN',
  payload: data,
});

export const logout = (data) => ({
  type: 'LOGOUT',
  payload: data,
});

export const toggleLoader = (data) => ({
  type: 'TOGGLE_LOADER',
  payload: data,
});

export const innerForm = (data) => ({
  type: 'INNER_FORM',
  payload: data,
});

export const setRegisterEmail = (data) => ({
  type: 'SET_REGISTER_EMAIL',
  payload: data,
});

export const getUserProfile = () => ({
  type: 'GET_USER_PROFILE',
});

export const setUserProfile = (data) => ({
  type: 'SET_USER_PROFILE',
  payload: data
});

export const updateProfile = (data) => ({
  type: 'UPDATE_PROFILE',
  payload: data
});

export const buyItem = (data) => ({
  type: 'SET_BUY_ITEM',
  payload: data
});


/*========== MEDIA ACTIONS ============= */

export const getMediaItems = () => ({
  type: 'GET_MEDIA_ITEMS'
});

export const setMediaItems = data => ({
  type: 'SET_MEDIA_ITEMS',
  payload: data
});

/*========== Vote ACTIONS ============= */

export const getVotings = data => ({
  type: 'GET_VOTING',
  payload: data
});

export const setVotings = data => ({
  type: 'SET_VOTING',
  payload: data
});

export const getSingleVotings = data => ({
  type: 'GET_SINGLE_VOTING',
  payload: data
});

export const setSingleVotings = data => ({
  type: 'SET_SINGLE_VOTING',
  payload: data
});

export const addVote = data => ({
  type: 'ADD_VOTE',
  payload: data
});

export const submitVotingChoice = data => ({
  type: 'SUBMIT_VOTING_CHOICE',
  payload: data
});

export const setVote = data => ({
  type: 'SET_VOTE',
  payload: data
});
/*========== Sale ACTIONS ============= */

export const getSaleItems = () => ({
  type: 'GET_SALE_ITEMS'
});

export const setSaleItems = data => ({
  type: 'SET_SALE_ITEMS',
  payload: data
});

export const getSaleItemsById = (data) => ({
  type: 'GET_SALE_ITEMS_BY_ID',
  payload: data
});

export const setSaleItemsById = data => ({
  type: 'SET_SALE_ITEMS_BY_ID',
  payload: data
});

export const itemSold = data => ({
  type: 'ITEM_SOLD',
  payload: data
});

export const setSale = data => ({
  type: 'SET_SALE',
  payload: data
});

export const getItemSold = data => ({
  type: 'GET_ITEM_SOLD',
  payload: data
});

export const setItemSold = data => ({
  type: 'SET_ITEM_SOLD',
  payload: data
});

/*========== Sale ACTIONS ============= */

export const getEventSaleItems = () => ({
  type: 'GET_EVENT_SALE_ITEMS'
});

export const setEventSaleItems = data => ({
  type: 'SET_EVENT_SALE_ITEMS',
  payload: data
});

export const getEventSaleItemsById = (data) => ({
  type: 'GET_EVENT_SALE_ITEMS_BY_ID',
  payload: data
});

export const setEventSaleItemsById = data => ({
  type: 'SET_EVENT_SALE_ITEMS_BY_ID',
  payload: data
});

export const buyEventItem = (data) => ({
  type: 'BUY_EVENT_ITEM',
  payload: data
});

export const donateAmount = (data) => ({
  type: 'DONATE_AMOUNT',
  payload: data
});

/*========== Regiister ============= */

export const setRegisterUser = (data) => ({
  type: 'SET_REGISTER_USER',
  payload: data,
});

export const getRegisterUser = (data) => ({
  type: 'GET_REGISTER_USER',
  payload: data,
});

export const setVerifyCode = (data) => ({
  type: 'SET_VERIFY_CODE',
  payload: data,
});

export const getVerifyCode = (data) => ({
  type: 'GET_VERIFY_CODE',
  payload: data,
});

export const setUserHaveEmail = (data) => ({
  type: 'SET_USER_EMAIL',
  payload: data,
});

export const getUserHaveEmail = (data) => ({
  type: 'GET_USER_EMAIL',
  payload: data,
});

export const setUserForget = (data) => ({
  type: 'SET_USER_FORGET',
  payload: data,
});

export const getUserForget = (data) => ({
  type: 'GET_USER_FORGET',
  payload: data,
});
